.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation__label,
.react-calendar__navigation__arrow,
.react-calendar__navigation {
  padding-top: 4px;
  background-color: inherit;
  color: black;
  border: 0;
  font-weight: bold;
}

.react-calendar__viewContainer {
  padding-bottom: 4px;
}

.react-calendar__month-view__weekdays {
  background-color: inherit;
  text-align: center;
  color: black;
}
.react-calendar__month-view__weekdays abbr {
  border: 0;
  text-decoration: none;
  cursor: default;
  padding: 4px 0;
}
.react-calendar__month-view__days {
  background-color: inherit;
  gap: 5px 0px;
}
.react-calendar__tile,
.react-calendar__tile--now {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  background-color: inherit;
  height: 32px;
  padding: 0;
  border: 0px;
}
.react-calendar__navigation__label,
.react-calendar__navigation > button:focus,
.time-picker-calendar-tile:focus {
  outline: 0;
}
.react-calendar__tile--hasActive:not(.react-calendar__tile--range),
.react-calendar__tile--active,
.react-calendar__tile--active:hover {
  color: white;
  font-weight: bold;
  background: #00a0df;
  box-shadow: none;
  border: 0px;
}
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeStart {
  padding: 0;
  border: 0px;
}
.react-calendar__tile--rangeStart abbr {
  border-radius: 100px;
  padding-top: 2px;
}
.react-calendar__tile--hasActive:not(.react-calendar__tile--range),
.react-calendar__tile--rangeStart {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #00a0df;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--hasActive:not(.react-calendar__tile--range),
.react-calendar__tile--rangeEnd {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #00a0df;
  font-weight: bold;
  color: white;
}
