.react-resizable-handle {
  background: none;
}
.react-resizable-handle-nw {
  position: absolute;
  width: 100%;
  height: 10px;
  cursor: ns-resize;
  top: 0;
  background-color: white;
  transition: background-color 0.2s ease-in-out;
}

.react-resizable-handle-nw:hover {
  background-color: #8e8ed4;
}
